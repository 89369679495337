import React, { useState } from "react";
import "./Hero.css";
import profilePic from "../../images/profilePic.jpg";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../redux/userSlicer";

function Hero() {
  const [username, setUserName] = useState("");
  const name = useSelector((state) => state.user.name);
  const dispatch = useDispatch();

  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(update({ username }));
  };
  return (
    <div className="hero">
      <div className="heroLeft">
        <div className="home">
          <HomeIcon />
          <span>Home</span>
        </div>
        <div className="logout">
          <LogoutIcon />
          <span>
            Logout.
            <span
              style={{
                font: "20px",
                color: "#764abc",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              {name}
            </span>
            .
          </span>
        </div>
      </div>
      <div className="heroCenter">
        <div className="centerTop">
          <h4>Update your account</h4>
          <span className="Warn">
            <span
              style={{
                font: "20px",
                color: "#764abc",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              {name}
            </span>
            ! You are about to update your username using REDUX. This will
            change all username throught the app in all components.
          </span>
        </div>
        <div className="centerBottom">
          <div className="pic">
            <img src={profilePic} alt="" />
          </div>
          <div className="username">
            <span>Username:</span>
            <input
              type="text"
              placeholder="Your new user name"
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <button className="update" onClick={handleUpdate}>
            Update
          </button>
        </div>
      </div>
      <div className="heroRight">
        <h2>
          Recommend for:{" "}
          <span
            style={{
              font: "20px",
              color: "#764abc",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
          >
            {name}
          </span>
        </h2>
        <img src={profilePic} alt="" />
      </div>
    </div>
  );
}

export default Hero;
