import React from "react";
import "./Navbar.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import profilePic from "../../images/profilePic.jpg";
import { useSelector } from "react-redux";
function Navbar() {
  const name = useSelector((state) => state.user.name);
  return (
    <div className="navbar">
      <div className="navLeft">
        <h1 className="logo">Redux</h1>
      </div>
      <div className="navCenter">
        <input type="text" placeholder="search for something..." />
      </div>
      <div className="navRight">
        <div className="profilePic">
          <img src={profilePic} alt="" />
        </div>
        <span
          style={{
            font: "20px",
            color: "#764abc",
            textTransform: "capitalize",
            fontWeight: "bold",
          }}
        >
          {name}
        </span>
        <ArrowDropDownIcon />
      </div>
    </div>
  );
}

export default Navbar;
